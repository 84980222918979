<template>
  <b-row>
    <b-col md="12">
      <b-card
        title="Редактирование порядка элементов"
        class="mb-4 home_change_position_card"
      >
        <!-- collapse -->
        <app-collapse id="home_change_position_collapse" accordion class="">
          <app-collapse-item
            class="home_change_position_collapse_item"
            title="Слайдеры"
          >
            <draggable
              :list="getSliders"
              :animation="150"
              @end="onSlidersChange"
              class="cursor-move"
            >
              <b-badge
                v-for="(listItem, index) in getSliders"
                :key="index"
                class="mr-50 drag-drop-badge"
              >
                {{ listItem.name }}
              </b-badge>
            </draggable>
          </app-collapse-item>
          <app-collapse-item
            class="home_change_position_collapse_item"
            title="Категории"
            :isVisible="true"
          >
            <draggable
              :list="getCategories.categories"
              :animation="150"
              @end="onCategoriesChange"
              class="cursor-move"
            >
              <b-badge
                v-for="(listItem, index) in getCategories.categories"
                :key="index"
                class="mr-50 drag-drop-badge"
              >
                {{ listItem.titleRu }}
              </b-badge>
            </draggable>
          </app-collapse-item>
        </app-collapse>

        <!--/ collapse -->
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
  BAvatar,
  BCard,
  BCardText,
  BCardTitle,
  BCardBody,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdownItem,
  BDropdown,
} from "bootstrap-vue";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import draggable from "vuedraggable";
import { mapGetters } from "vuex";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import axiosIns from "@/libs/axios.js";

export default {
  components: {
    draggable,
    BRow,
    BCol,
    BAvatar,
    BBadge,
    BPagination,
    BCard,
    BCardText,
    BCardTitle,
    BCardBody,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdownItem,
    BDropdown,
    AppCollapse,
    AppCollapseItem,
  },
  computed: {
    ...mapGetters(["getCategories", "getSliders"]),
  },
  props: {},
  data() {
    return {};
  },
  mounted() {},
  methods: {
    async onCategoriesChange() {
      const changedCats = this.getCategories.categories.map(function (item) {
        return item.id;
      });
      await axiosIns
        .post("vendor-category/change-order", {
          orderedByIdArray: changedCats,
        })
        .then((response) => {
          if (response.status == 200) {
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: `Успешно`,
                  icon: "CheckCircleIcon",
                  variant: "success",
                  text: `Порядок категорий успешно была изменена!`,
                },
              },
              {
                position: "top-right",
              }
            );
          }
        })
        .catch((error) => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: `Ошибка`,
                icon: "XIcon" /* XCircleIcon AlertCircleIcon AlertTriangleIcon */,
                variant: "danger",
                text: error.response.data.errorMessage,
              },
            },
            {
              position: "top-right",
            }
          );
          if (error.response) {
            // Request made and server responded
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            // The request was made but no response was received
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message);
          }
        });
    },
    async onSlidersChange() {
      const changedSliders = this.getSliders.map(function (item) {
        return item.id;
      });
      await axiosIns
        .post("v1/slider/change-order", {
          sliderIdList: changedSliders,
        })
        .then((response) => {
          if (response.status == 200) {
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: `Успешно`,
                  icon: "CheckCircleIcon",
                  variant: "success",
                  text: `Порядок слайдеров успешно была изменена!`,
                },
              },
              {
                position: "top-right",
              }
            );
          }
        })
        .catch((error) => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: `Ошибка`,
                icon: "XIcon" /* XCircleIcon AlertCircleIcon AlertTriangleIcon */,
                variant: "danger",
                text: error.response.data.errorMessage,
              },
            },
            {
              position: "top-right",
            }
          );
          if (error.response) {
            // Request made and server responded
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            // The request was made but no response was received
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message);
          }
        });
    },
  },
};
</script>

<style lang="scss">
.drag-drop-badge {
  background: #fff;
  color: #5e5873;
  box-shadow: 0 4px 12px 0 rgb(34 41 47 / 10%);
  font-size: 14px;
  padding: 0.8rem 1.2rem;
  border-radius: 50px;
  margin-bottom: 0.7rem;
}
</style>
