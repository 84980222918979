<template>
  <div>
    <validation-observer ref="simpleRules">
      <b-form>
        <b-row>
          <b-col md="4">
            <validation-provider
              #default="{ errors }"
              name="Название мерчанта"
              rules="required"
            >
              <b-form-group
                label="Название мерчанта"
                label-for="new-merch-title"
              >
                <b-form-input
                  v-model="name"
                  id="new-merch-title"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Название"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col md="4">
            <validation-provider
              #default="{ errors }"
              name="Категория"
              rules="required"
            >
              <b-form-group label="Категория" label-for="new-merch-category">
                <v-select
                  id="new-merch-category"
                  multiple
                  placeholder="Выберите категории"
                  :state="errors.length > 0 ? false : null"
                  v-model="selectedCategories"
                  label="titleRu"
                  :options="getCategories.categories"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col md="4">
            <validation-provider
              #default="{ errors }"
              name="Лого мерчанта"
              rules="required"
            >
              <b-form-group label="Лого мерчанта" label-for="new-merch-logo">
                <b-form-file
                  v-model="file"
                  @change="handleFileUpload($event)"
                  :state="errors.length > 0 ? false : null"
                  id="new-merch-logo"
                  accept="image/png, image/jpeg, image/tiff"
                  placeholder="Выберите изображение..."
                  drop-placeholder="Drop file here..."
                />
                <span class="sm-size"
                  >(Поддерживаются файлы в формате .jpg, .png, .tiff)
                </span>
                <!-- <b-card-text class="my-1">
                Выбранный файл: <strong>{{ file ? file.name : "" }}</strong>
              </b-card-text> -->
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col md="4">
            <validation-provider
              #default="{ errors }"
              name="Описание RU"
              rules="required"
            >
              <b-form-group label="Описание RU" label-for="new-merch-desc-ru">
                <b-form-textarea
                  id="new-merch-desc-ru"
                  v-model="descriptionRu"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Описание"
                  rows="3"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col md="4">
            <validation-provider
              #default="{ errors }"
              name="Описание UZ"
              rules="required"
            >
              <b-form-group label="Описание UZ" label-for="new-merch-desc-uz">
                <b-form-textarea
                  id="new-merch-desc-uz"
                  v-model="descriptionUz"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Описание"
                  rows="3"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col md="4">
            <validation-provider
              #default="{ errors }"
              name="Описание EN"
              rules="required"
            >
              <b-form-group label="Описание EN" label-for="new-merch-desc-en">
                <b-form-textarea
                  id="new-merch-desc-en"
                  v-model="descriptionEn"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Описание"
                  rows="3"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col md="4">
            <validation-provider
              #default="{ errors }"
              name="Тег"
              rules="required"
            >
              <b-form-group label="Тег" label-for="new-merch-branch-tag">
                <v-select
                  id="new-merch-branch-tag"
                  placeholder="Выберите тег"
                  :state="errors.length > 0 ? false : null"
                  v-model="tag"
                  label="title"
                  :options="getTags"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col md="4">
            <validation-provider
              #default="{ errors }"
              name="Кешбэк"
              rules="required"
            >
              <b-form-group label="Кешбэк" label-for="new-merch-cashback">
                <b-form-input
                  v-model="cashbackDiscount"
                  :state="errors.length > 0 ? false : null"
                  id="new-merch-cashback"
                  type="number"
                  @input="checkDiscount"
                  v-mask="'###'"
                  placeholder="Кешбэк"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col md="12" class="pt-2">
            <h4 class="new-merch-braches-title pb-1">Филиалы</h4>
          </b-col>

          <b-col md="12">
            <div>
              <b-form
                ref="form"
                :style="{ height: trHeight }"
                class="repeater-form"
                @submit.prevent="repeateAgain"
              >
                <b-row
                  v-for="(item, index) in items"
                  :id="item.id"
                  :key="item.id"
                  ref="row"
                >
                  <b-col md="4">
                    <validation-provider
                      #default="{ errors }"
                      name="Название филиала"
                      rules="required"
                    >
                      <b-form-group
                        label="Название филиала"
                        label-for="new-merch-branch-title"
                      >
                        <b-form-input
                          id="new-merch-branch-title"
                          placeholder="Название"
                          :state="errors.length > 0 ? false : null"
                          v-model="item.name"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <b-col md="4">
                    <validation-provider
                      #default="{ errors }"
                      name="Лого филиала"
                      rules="required"
                    >
                      <b-form-group>
                        <label>Лого филиала</label>
                        <b-form-file
                          v-model="item.logoFile"
                          accept="image/png, image/jpeg, image/tiff"
                          :state="errors.length > 0 ? false : null"
                          @change="handleFileUploadBranchLogo(item, $event)"
                          placeholder="Выберите изображение..."
                          drop-placeholder="Drop file here..."
                        />
                        <span class="sm-size"
                          >(Поддерживаются файлы в формате .jpg, .png, .tiff)
                        </span>
                        <!-- <b-card-text class="my-1">
                Выбранный файл: <strong>{{ file ? file.name : "" }}</strong>
              </b-card-text> -->
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <b-col md="4">
                    <validation-provider
                      #default="{ errors }"
                      name="Изображение"
                      rules="required"
                    >
                      <b-form-group>
                        <label>Изображение</label>

                        <b-form-file
                          v-model="item.photoFile"
                          accept="image/png, image/jpeg, image/tiff"
                          :state="errors.length > 0 ? false : null"
                          @change="handleFileUploadBranchPhoto(item, $event)"
                          placeholder="Выберите изображение..."
                          drop-placeholder="Drop file here..."
                        />
                        <span class="sm-size"
                          >(Поддерживаются файлы в формате .jpg, .png, .tiff)
                        </span>
                        <!-- <b-card-text class="my-1">
                Выбранный файл: <strong>{{ file ? file.name : "" }}</strong>
              </b-card-text> -->
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <b-col md="4">
                    <validation-provider
                      #default="{ errors }"
                      name="Адрес"
                      rules="required"
                    >
                      <b-form-group
                        label="Адрес"
                        label-for="new-merch-branch-address"
                      >
                        <b-form-input
                          id="new-merch-branch-address"
                          placeholder="Адрес"
                          :state="errors.length > 0 ? false : null"
                          v-model="item.address"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <b-col md="4">
                    <validation-provider
                      #default="{ errors }"
                      name="Номер телефона"
                      rules="required"
                    >
                      <b-form-group
                        label="Номер телефона"
                        label-for="new-merch-branch-phoneNumber"
                      >
                        <b-form-input
                          id="new-merch-branch-phoneNumber"
                          placeholder="Номер телефона"
                          :state="errors.length > 0 ? false : null"
                          v-model="item.phoneNumber"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <b-col md="4">
                    <validation-provider
                      #default="{ errors }"
                      name="Город"
                      rules="required"
                    >
                      <b-form-group
                        label="Город"
                        label-for="new-merch-branch-city"
                      >
                        <v-select
                          id="new-merch-branch-city"
                          placeholder="Выберите город"
                          v-model="item.selectedCity"
                          :state="errors.length > 0 ? false : null"
                          label="nameRu"
                          :options="getCities.cities"
                          @input="citySelect(item)"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <b-col md="12">
                    <b-form-group
                      label="Локация"
                      label-for="new-merch-branch-location"
                    >
                      <yandex-map
                        id="new-merch-branch-location"
                        :settings="mapSettings"
                        :coords="baseCoords"
                        :zoom="12.5"
                        @click="onClick(item, $event)"
                        class="new-branch-map"
                      >
                        <ymap-marker
                          markerId="123"
                          :coords="item.coords"
                          hint-content="some hint"
                        />
                      </yandex-map>
                    </b-form-group>
                    <small
                      v-if="item.locationRequired === 2"
                      class="text-danger"
                      >Поле Локация обязательно для заполнения
                    </small>
                  </b-col>
                  <b-col
                    md="12"
                    class="mb-50 d-flex justify-content-end align-items-center"
                  >
                    <b-button
                      v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                      variant="outline-danger"
                      size="sm"
                      @click="removeItem(index)"
                    >
                      <feather-icon icon="Trash2Icon" class="mr-25" />
                      <span>Удалить</span>
                    </b-button>
                  </b-col>

                  <b-col cols="12">
                    <hr />
                  </b-col>
                </b-row>
              </b-form>
            </div>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              size="sm"
              @click="repeateAgain"
            >
              <feather-icon icon="PlusIcon" class="mr-25" />
              <span>Добавить</span>
            </b-button>
          </b-col>
          <!-- submit and reset -->

          <b-col class="mt-2 justify-content-end d-flex">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-1"
              @click.prevent="handleSubmit"
            >
              Добавить
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="reset"
              variant="outline-secondary"
            >
              Сбросить
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BButton,
  BFormTextarea,
  BCardText,
  BFormFile,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import { heightTransition } from "@core/mixins/ui/transition";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import { yandexMap, ymapMarker } from "vue-yandex-maps";
import { mapGetters } from "vuex";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import axiosIns from "@/libs/axios.js";

export default {
  components: {
    yandexMap,
    ymapMarker,
    BFormFile,
    BFormTextarea,
    BCardText,
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    vSelect,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  computed: {
    ...mapGetters(["getCategories", "getCities", "getTags"]),
    /* getCategoriesTitle() {
      return this.getCategories.map(function (category) {
        return category.titleRu;
      });
    }, */
  },
  data() {
    return {
      required,
      name: null,
      descriptionRu: null,
      descriptionUz: null,
      descriptionEn: null,
      tag: null,
      cashbackDiscount: null,
      file: null,
      fileUrl: null,
      selectedCategories: null,
      items: [
        {
          id: 0,
          name: null,
          logoFile: null,
          logo: null,
          photoFile: null,
          photo: null,
          name: null,
          address: null,
          phoneNumber: null,
          selectedCity: null,
          cityId: null,
          baseCoords: [41.31136244660736, 69.27975446613132],
          coords: [],
          locationRequired: 0,
        },
      ],
      nextTodoId: 0,
      baseCoords: [41.31136244660736, 69.27975446613132],
      coords: [],
      mapSettings: {
        apiKey: "ff6e2790-0d09-4e1a-b157-adfec8de90d7",
        lang: "ru_RU",
        coordorder: "latlong",
        enterprise: false,
        version: "2.1",
      },
    };
  },
  mounted() {
    this.initTrHeight();
  },
  created() {
    window.addEventListener("resize", this.initTrHeight);
  },
  destroyed() {
    window.removeEventListener("resize", this.initTrHeight);
  },
  methods: {
    citySelect(item) {
      item.cityId = item.selectedCity.id;
    },
    /* async handleSubmit() {
      const selectedCategoriesId = this.selectedCategories.map(function (item) {
        return item.id;
      });
      const response = await axiosIns
        .post("vendor/create", {
          name: this.name,
          vendorDescriptionRu: this.descriptionRu,
          vendorDescriptionUz: this.descriptionUz,
          vendorDescriptionEn: this.descriptionEn,
          categoryIdList: selectedCategoriesId,
          businessType: "FOOD",
          cashbackDiscount: 0,
          branchesIdList: [],
          logoUrl: "music.ads",
        })
        .catch((error) => {
          if (error.response) {
            // Request made and server responded
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            // The request was made but no response was received
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message);
          }
        })
        .finally(() => this.resetForm());
      if (response.status == 200) {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: `Успешно создан`,
            icon: "CoffeeIcon",
            variant: "success",
            text: `Ваш мерчант успешно создан!`,
          },
        });
      }
    }, */
    checkDiscount() {
      if (this.cashbackDiscount > 100) {
        this.cashbackDiscount = 100;
      }
    },
    async handleSubmit() {
      this.$refs.simpleRules.validate().then((success) => {
        const mapValidation = this.items.every(
          (item) => item.locationRequired === 1
        );
        if (success && mapValidation) {
          const selectedCategoriesId = this.selectedCategories.map(function (
            item
          ) {
            return item.id;
          });
          axiosIns
            .post("vendor/create", {
              categoryIdList: selectedCategoriesId,
              logoUrl: this.fileUrl,
              name: this.name,
              businessType: "FOOD",
              vendorDescriptionRu: this.descriptionRu,
              vendorDescriptionUz: this.descriptionUz,
              vendorDescriptionEn: this.descriptionEn,
              cashbackDiscount: this.cashbackDiscount,
              branchList: this.items,
            })
            .then((response) => {
              if (response.status == 200) {
                this.$toast(
                  {
                    component: ToastificationContent,
                    props: {
                      title: `Успешно`,
                      icon: "CheckCircleIcon",
                      variant: "success",
                      text: `Мерчант был успешно создан!`,
                    },
                  },
                  {
                    position: "top-right",
                  }
                );
              }
            })
            .catch((error) => {
              this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: `Ошибка`,
                    icon: "XIcon" /* XCircleIcon AlertCircleIcon AlertTriangleIcon */,
                    variant: "danger",
                    text: error.response.data.errorMessage,
                  },
                },
                {
                  position: "top-right",
                }
              );
              if (error.response) {
                // Request made and server responded
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
              } else if (error.request) {
                // The request was made but no response was received
                console.log(error.request);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log("Error", error.message);
              }
            })
            .finally(() => {
              this.resetForm();
              this.$refs.simpleRules.reset();
            });
        } else {
          this.items.forEach((element) => {
            if (element.locationRequired === 0) {
              element.locationRequired = 2;
            }
          });
        }
      });
    },
    async handleFileUpload(event) {
      this.file = event.target.files[0];
      const formData = new FormData();
      formData.append("logo", this.file);
      const response = await axiosIns.post("image/upload/vendor", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      this.fileUrl = response.data.data.imageUrl;
    },
    async handleFileUploadBranchLogo(item, event) {
      item.logoFile = event.target.files[0];
      const formData = new FormData();
      formData.append("logo", item.logoFile);
      const response = await axiosIns.post("image/upload/branch", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      item.logo = response.data.data.imageUrl;
    },
    async handleFileUploadBranchPhoto(item, event) {
      item.photoFile = event.target.files[0];
      const formData = new FormData();
      formData.append("logo", item.photoFile);
      const response = await axiosIns.post("image/upload/branch", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      item.photo = response.data.data.imageUrl;
    },
    onClick(item, event) {
      this.items[item.id].coords = event.get("coords");
      const selectedCoords = event.get("coords");
      const location = {
        latitude: selectedCoords[0],
        longitude: selectedCoords[1],
      };
      this.items[item.id].location = location;
      this.items[item.id].locationRequired = 1;

      /* this.coords = e.get("coords"); */
    },
    validationForm() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          // eslint-disable-next-line
          alert("form submitted!");
        }
      });
    },
    repeateAgain() {
      this.items.push({
        id: (this.nextTodoId += 1),
        name: null,
        logoFile: null,
        logo: null,
        photoFile: null,
        photo: null,
        name: null,
        address: null,
        phoneNumber: null,
        selectedCity: null,
        cityId: null,
        baseCoords: [41.31136244660736, 69.27975446613132],
        coords: [],
        locationRequired: 0,
      });
      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight);
      });
    },
    removeItem(index) {
      this.items.splice(index, 1);
      this.trTrimHeight(this.$refs.row[0].offsetHeight);
    },
    initTrHeight() {
      this.trSetHeight(null);
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight);
      });
    },
    resetForm() {
      this.name = null;
      this.descriptionRu = null;
      this.descriptionUz = null;
      this.descriptionEn = null;
      this.tag = null;
      this.cashbackDiscount = null;
      this.file = null;
      this.fileUrl = null;
      this.selectedCategories = null;
      (this.items = [
        {
          id: 0,
          name: null,
          logoFile: null,
          logo: null,
          photoFile: null,
          photo: null,
          name: null,
          address: null,
          phoneNumber: null,
          selectedCity: null,
          cityId: null,
          baseCoords: [41.31136244660736, 69.27975446613132],
          coords: [],
        },
      ]),
        (this.nextTodoId = 0);
      this.baseCoords = [41.31136244660736, 69.27975446613132];
      this.initTrHeight();
    },
  },
};
</script>

<style scoped>
.new-merch-braches-title {
  font-size: 16px;
}
.mt-07 {
  margin-top: 0.7rem;
}
</style>
