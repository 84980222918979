<template>
  <section id="home_section">
    <h1 class="mb-2">Главная</h1>
    <b-row class="match-height">
      <b-col lg="8" md="12">
        <!-- collapse -->
        <app-collapse
          id="home_merchants_collapse"
          accordion
          type="margin"
          class=""
        >
          <app-collapse-item
            class="new-merch-collapse mt-0"
            :isVisible="true"
            title="Новый мерчант"
          >
            <HomeCreateMerchant />
          </app-collapse-item>
          <app-collapse-item
            class="new-merch-collapse"
            title="Список мерчантов"
          >
            <HomeMerchantsList />
          </app-collapse-item>
        </app-collapse>

        <!--/ collapse -->
      </b-col>
      <b-col lg="4" md="12">
        <HomeChangePosition />
      </b-col>
    </b-row>
  </section>
</template>

<script>
import { BRow, BCol } from "bootstrap-vue";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import HomeCreateMerchant from "../home/HomeCreateMerchant.vue";
import HomeMerchantsList from "../home/HomeMerchantsList.vue";
import HomeChangePosition from "../home/HomeChangePosition.vue";
import { mapActions } from 'vuex'

export default {
  components: {
    BRow,
    BCol,
    AppCollapse,
    AppCollapseItem,
    HomeCreateMerchant,
    HomeMerchantsList,
    HomeChangePosition
  },
  data() {
    return {
    };
  },
  created() {
    this.fetchCategories();
    this.fetchSliders();
    this.fetchTags();
    this.fetchCities();
  },
  methods: {
    ...mapActions(['fetchSliders','fetchCategories', 'fetchTags', 'fetchCities'])
  }
};
</script>

<style scoped>
.new-merch-collapse .collapse-title {
  font-size: 1.3rem;
}
.new-merch-collapse {
  box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%) !important;
}
</style>
