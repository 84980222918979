<template>
  <b-row>
    <b-col md="4">
      <!-- search input -->
      <div class="custom-search">
        <b-form-group class="mb-25">
          <h6>Фильтры</h6>
          <div class="d-flex align-items-center"></div>
        </b-form-group>
        <b-row>
          <b-col cols="12" md="12" class="mb-1">
            <label>Поиск</label>
            <!-- basic search -->
            <b-input-group class="input-group-merge res-w-100 mr-2 res-mt-2">
              <b-input-group-prepend is-text>
                <feather-icon icon="SearchIcon" />
              </b-input-group-prepend>
              <b-form-input
                class="res-w-100"
                @input="handleSearch"
                v-model="searchTerm"
                placeholder="Поиск"
              />
            </b-input-group>
          </b-col>
          <b-col cols="12" md="12" class="mb-1">
            <label>Тег</label>
            <v-select
              v-model="tag"
              :options="getTags"
              placeholder="Выберите тег"
              class="w-100"
              label="businessType"
              @input="filterByTag()"
            />
          </b-col>
          <b-col cols="12" md="12" class="mb-1">
            <label>Категория</label>
            <v-select
              v-model="category"
              :options="getCategories.categories"
              placeholder="Выберите категорию"
              class="w-100"
              label="titleRu"
              @input="filterByCategory()"
            />
          </b-col>
          <b-col cols="12" md="12" class="mb-1">
            <label>Статус</label>
            <v-select
              v-model="status"
              placeholder="Выберите Статус"
              :options="statuses"
              class="w-100"
              @input="filterByStatus()"
            />
          </b-col>
        </b-row>
      </div>
    </b-col>
    <b-col md="8">
      <div class="d-flex res-align-items-start res-flex-column mb-1">
        <div class="d-flex align-items-center">
          <span class="text-nowrap">Отображать по:</span>
          <v-select
            v-model="pageLength"
            :options="pages"
            :clearable="false"
            @input="handlePageLengthChange"
            class="per-page-selector d-inline-block mx-50"
          />
        </div>
        <div class="res-w-100" v-if="selectedRows && selectedRows.length > 0">
          <b-dropdown
            class="res-mt-2 res-w-100"
            id="dropdown-1"
            right
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            text="Редактировать выбранные"
            variant="primary"
            menu-class="w-100"
          >
            <b-dropdown-item
              v-for="(item, i) in merchantStatuses"
              :key="i"
              @click.prevent="editSelectedRows(item)"
            >
              {{ item.title }}
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </div>

      <!-- table -->
      <vue-good-table
        mode="remote"
        :columns="columns"
        :rows="rows"
        :rtl="false"
        :search-options="{
          enabled: true,
          externalQuery: searchTerm,
        }"
        :select-options="{
          enabled: true,
          selectOnCheckboxOnly: true,
          selectionInfoClass: 'custom-class',
          selectionText: 'rows selected',
          clearSelectionText: 'clear',
          disableSelectInfo: true,
          selectAllByGroup: true,
        }"
        :pagination-options="{
          enabled: true,
        }"
        @on-sort-change="onSortChange"
        @on-selected-rows-change="onSelectChange"
      >
        <template slot="table-row" slot-scope="props">
          <!-- Column: Name -->
          <span v-if="props.column.field === 'name'" class="text-nowrap">
            <router-link :to="{ path: '/' }">
              <b-avatar
                size="md"
                :src="props.row.avatar"
                class="mr-1 badge-light-primary"
              />
              <span class="text-nowrap font-weight-bold text-nowrap">{{
                props.row.name
              }}</span>
            </router-link>
          </span>

          <!-- Column: Status -->
          <span v-else-if="props.column.field === 'status'">
            <b-badge :variant="statusVariant(props.row.vendorState)">
              {{ props.row.vendorState }}
            </b-badge>
          </span>

          <!-- Column: Action -->
          <span v-else-if="props.column.field === 'action'">
            <span>
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="text-body align-middle mr-25"
                  />
                </template>
                <b-dropdown-item
                  :to="{
                    name: 'merchant-edit',
                    params: { id: props.row.id },
                  }"
                >
                  <feather-icon icon="Edit2Icon" class="mr-50" />
                  <span>Изменить</span>
                </b-dropdown-item>
                <b-dropdown-item>
                  <feather-icon icon="TrashIcon" class="mr-50" />
                  <span>Удалить</span>
                </b-dropdown-item>
              </b-dropdown>
            </span>
          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
          <div class="d-flex justify-content-end flex-wrap">
            <!-- pagination -->
            <div>
              <b-pagination
                :value="1"
                :total-rows="20"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @change="handleChangePage"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdownItem,
  BDropdown,
  BInputGroup,
  BInputGroupPrepend,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import vSelect from "vue-select";
import axiosIns from "@/libs/axios.js";
import { mapGetters } from "vuex";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    vSelect,
    BInputGroup,
    BInputGroupPrepend,
    BRow,
    BCol,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdownItem,
    BDropdown,
  },
  data() {
    return {
      selectedRows: null,
      category: null,
      status: null,
      tag: null,
      statuses: ["ACTIVE", "DELETED", "BLOCKED", "SUSPENDED"],
      pageLength: 5,
      currentPage: 1,
      pages: ["3", "5", "10"],
      totalCount: null,
      merchantStatuses: [
        {
          title: "Активировать",
          value: "ACTIVE",
        },
        {
          title: "Приостановить",
          value: "SUSPENDED",
        },
        {
          title: "Блокировать",
          value: "BLOCKED",
        },
        {
          title: "Удалить",
          value: "DELETED",
        },
      ],
      columns: [
        {
          label: "ID",
          field: "id",
        },
        {
          label: "Название",
          field: "name",
        },
        {
          label: "Тег",
          field: "businessType",
        },
        {
          label: "Статус",
          field: "status",
        },
        {
          label: "Действие",
          field: "action",
        },
      ],
      rows: [],
      searchTerm: "",
    };
  },
  computed: {
    ...mapGetters(["getCategories", "getTags"]),
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        ACTIVE: "light-success",
        Professional: "light-primary",
        Rejected: "light-danger",
        Resigned: "light-warning",
        Applied: "light-info",
        /* eslint-enable key-spacing */
      };

      return (status) => statusColor[status];
    },
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      const response = await axiosIns.get(`vendor/all`, {
        params: {
          page: this.currentPage,
          size: this.pageLength,
          sort: this.sortField,
          field: this.field,
          fieldValue: this.fieldValue,
        },
      });
      this.rows = response.data.data.vendors;
      this.totalCount = response.data.data.totalCount;
    },
    handleSearch(searching) {
      alert(`The user searched for: ${searching}`);
    },
    handleSearch(searching) {
      this.field = "name";
      this.fieldValue = searching;
      this.getData();
    },
    async handleChangePage(page) {
      this.currentPage = page;
      this.getData();
    },
    async handlePageLengthChange(active) {
      this.pageLength = active;
      this.getData();
    },
    onSortChange(params) {
      if (params[0].field !== "action") {
        this.sortField = params[0].field + "," + params[0].type;
        this.getData();
      }
    },
    filterByTag() {
      this.field = "businessType";
      this.fieldValue = this.tag.businessType;
      this.getData();
    },
    filterByCategory() {
      this.field = "vendorCategoryList";
      this.fieldValue = this.category.titleRu;
      this.getData();
    },
    filterByStatus() {
      this.field = "vendorState";
      this.fieldValue = this.status;
      this.getData();
    },
    onSelectChange(params) {
      const selectedRowsId = params.selectedRows.map(function (item) {
        return item.id;
      });
      this.selectedRows = selectedRowsId;
    },
    async editSelectedRows(item) {
      axiosIns
        .post("vendor/change-state", {
          vendorIdList: this.selectedRows,
          vendorState: item.value,
        })
        .then((response) => {
          if (response.status == 200) {
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: `Успешно`,
                  icon: "CheckCircleIcon",
                  variant: "success",
                  text: `Статус пользователя успешно изменен!`,
                },
              },
              {
                position: "top-right",
              }
            );
            this.getData();
          }
        })
        .catch((error) => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: `Ошибка`,
                icon: "XIcon" /* XCircleIcon AlertCircleIcon AlertTriangleIcon */,
                variant: "danger",
                text: error.response.data.errorMessage,
              },
            },
            {
              position: "top-right",
            }
          );
          if (error.response) {
            // Request made and server responded
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            // The request was made but no response was received
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message);
          }
        });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
